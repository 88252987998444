import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import roadshowAnalytics from '@/dealroadshow/application/actions/analytics/reducer';
import transactionTypes from '@/dealroadshow/application/actions/transactionType/reducer';
import Upload from '@/Framework/UI/Organisms/Upload';
import pageTitle from '@/condor/ui/common/PageTitle/reducers';
import breadcrumb from '@/condor/ui/common/Breadcrumb/reducers';
import dealRoadshows from '@/condor/ui/components/DealRoadshows/reducer';
import TechnicalSupport from '@/condor/ui/components/TechnicalSupport';
import user from '@/users/application/actions/reducer';
import condor from '@/condor/application/actions/reducer';
import companies from '@/users/application/actions/companies/reducer';
import bonds from '@/finsight/application/actions/bonds/reducer';
import filters from '@/Framework/UI/Organisms/Filters/reducer';
import checkboxes from '@/Framework/UI/Organisms/Filters/Checkboxer/reducer';
import { routerReducer } from 'connected-next-router';
import containerReducer from '@/Framework/DI/containerReducer';

export default combineReducers({
  // THIS IS LEGACY APPROACH DO NOT DO LIKE THIS
  router: routerReducer,
  container: containerReducer,
  match: (state) => state || null,
  transactionTypes,
  form: formReducer,
  breadcrumb,
  pageTitle,
  dealRoadshows,
  technicalSupport: TechnicalSupport.reducers,
  roadshowAnalytics,
  upload: Upload.reducers,
  checkboxes,
  filters,
  // THIS IS BETTER
  condor,
  // TODO:It is necessary to discuss with the leads, maybe need to connect the all finsight reducer
  finsight: combineReducers({
    bonds,
  }),
  user,
  companies,
});
