export const SECTOR = 'sector';
export const INDUSTRY = 'industry';
export const DEAL = 'deal';
export const DEALSTREAM = 'dealstream';
export const COMPANY = 'company';

export const ISSUER = 'issuer';
export const PARENT = 'parent';
export const DEALER = 'dealer';
export const UNDERWRITER = 'underwriter';
