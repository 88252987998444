import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EventLogOffset from './EventLogOffset';
import { setBreadcrumb, removeBreadcrumb } from '@/condor/ui/common/Breadcrumb/actions';
import { getFinsightStratsEventLogOffset, resetEventLogOffset } from '@/condor/application/actions/eventLogOffset/actions';
import config from '@/Framework/config';

function mapStateToProps(state) {
  return {
    isFetching: state.technicalSupport.eventLogOffset.isFetching,
    list: state.technicalSupport.eventLogOffset.list,
    lastUpdateTime: state.technicalSupport.eventLogOffset.lastUpdateTime,
    maxId: state.technicalSupport.eventLogOffset.maxId,
    breadcrumbName: 'Finsight Strats Event Log Offset',
    breadcrumbUrl: `${ config.tenant.condor.routes.base }/technical/finsight-strats/event-log-offset`,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getList: getFinsightStratsEventLogOffset,
    resetState: resetEventLogOffset,
    setBreadcrumb,
    removeBreadcrumb,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventLogOffset);
