import { Dependencies } from 'constitute';
import UploadDispatcher from '@/dmPortal/application/DI/FileUpload/UploadDispatcher';

@Dependencies(UploadDispatcher)
class LogoRepository {
  /**
   * @param {Dispatcher} uploadDispatcher
   */
  constructor(
    private uploadDispatcher: typeof UploadDispatcher,
  ) {}

  /**
   * Upload presentation to backend
   *
   * @param {File} file
   * @return {Upload}
   */
  upload = (file) => {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_LOGO' });
  };
}

export default LogoRepository;
