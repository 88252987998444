import * as actionTypes from './actionTypes';
import { INVENTORIES } from '@/finsight/domain/BondType';

const initialState = {
  search: '',
  assetClass: {},
  assetClassCollection: [],
  isAssetClassCollectionFetching: false,
  isExportFetching: false,
  isBlurred: true,
  isLoginModalOpen: false,
  totalCount: null,
  isFetching: false,
  activeTab: INVENTORIES,
};

export default function metadata(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_BOND_TOTAL_COUNT:
      return {
        ...state,
        isFetching: false,
        totalCount: action.payload.totalCount,
      };
    case actionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case actionTypes.BONDS_SEARCH_CHANGE:
      return {
        ...state,
        search: action.payload,
      };
    case actionTypes.GET_BONDS_EXPORT:
      return {
        ...state,
        isExportFetching: true,
      };
    case actionTypes.GET_BONDS_EXPORT_SUCCESS:
    case actionTypes.GET_BONDS_EXPORT_FAILURE:
      return {
        ...state,
        isExportFetching: false,
      };
    case actionTypes.GET_BONDS_ASSET_CLASSES:
      return {
        ...state,
        isAssetClassCollectionFetching: true,
      };
    case actionTypes.GET_BONDS_ASSET_CLASSES_SUCCESS:
      return {
        ...state,
        assetClassCollection: action.payload.collection,
        isAssetClassCollectionFetching: false,
      };
    case actionTypes.GET_BONDS_ASSET_CLASSES_FAILURE:
      return {
        ...state,
        isAssetClassCollectionFetching: false,
      };
    case actionTypes.BONDS_SET_ASSET_CLASS:
      return {
        ...state,
        assetClass: action.payload,
      };
    case actionTypes.BONDS_RESET_METADATA:
      return initialState;
    default:
      return state;
  }
}
