import * as actionTypes from './actionTypes';

const initialState = {};

export default function checkboxes(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHECKBOX_CHANGE_FIELD:
    case actionTypes.CHECKBOX_REGISTER_FIELD:
      if (action.payload.group) {
        return {
          ...state,
          [action.payload.group]: {
            ...state[action.payload.group],
            [action.payload.name]: {
              ...state[action.payload.name],
              parent: action.payload.parent || null,
              checked: action.payload.checked || false,
              disabled: action.payload.disabled || false,
              indeterminate: action.payload.indeterminate || false,
              supportCheckDisabled: action.payload.supportCheckDisabled || false,
            },
          },
        };
      }
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          parent: action.payload.parent || null,
          checked: action.payload.checked || false,
          disabled: action.payload.disabled || false,
          indeterminate: action.payload.indeterminate || false,
          supportCheckDisabled: action.payload.supportCheckDisabled || false,
        },
      };
    case actionTypes.CHECKBOX_UNREGISTER_FIELD:
      if (action.payload.name) {
        let newState = { ...state };
        if (action.payload.group) {
          delete newState[action.payload.group][action.payload.name];
        } else {
          delete newState[action.payload.name];
        }
        return newState;
      }
      return state;
    default:
      return state;
  }
}
