export const UPLOAD_CLEAR = 'UPLOAD_CLEAR';

// Presentation
export const UPLOAD_PRESENTATION_INIT = 'UPLOAD_PRESENTATION_INIT';
export const UPLOAD_PRESENTATION_ADD = 'UPLOAD_PRESENTATION_ADD';
export const UPLOAD_PRESENTATION_CHUNK_SUCCESS = 'UPLOAD_PRESENTATION_CHUNK_SUCCESS';
export const UPLOAD_PRESENTATION_SUCCESS = 'UPLOAD_PRESENTATION_SUCCESS';
export const UPLOAD_PRESENTATION_ERROR = 'UPLOAD_PRESENTATION_ERROR';
export const UPLOAD_PRESENTATION_PROCESSING = 'UPLOAD_PRESENTATION_PROCESSING';
export const UPLOAD_PRESENTATION_DONE = 'UPLOAD_PRESENTATION_DONE';
export const UPLOAD_PRESENTATION_CANCEL = 'UPLOAD_PRESENTATION_CANCEL';
export const UPLOAD_PRESENTATION_CANCEL_SUCCESS = 'UPLOAD_PRESENTATION_CANCEL_SUCCESS';

// Presentation slide
export const UPLOAD_PRESENTATION_SLIDE_INIT = 'UPLOAD_PRESENTATION_SLIDE_INIT';
export const UPLOAD_PRESENTATION_SLIDE_ADD = 'UPLOAD_PRESENTATION_SLIDE_ADD';
export const UPLOAD_PRESENTATION_SLIDE_CHUNK_SUCCESS = 'UPLOAD_PRESENTATION_SLIDE_CHUNK_SUCCESS';
export const UPLOAD_PRESENTATION_SLIDE_SUCCESS = 'UPLOAD_PRESENTATION_SLIDE_SUCCESS';
export const UPLOAD_PRESENTATION_SLIDE_ERROR = 'UPLOAD_PRESENTATION_SLIDE_ERROR';
export const UPLOAD_PRESENTATION_SLIDE_PROCESSING = 'UPLOAD_PRESENTATION_SLIDE_PROCESSING';
export const UPLOAD_PRESENTATION_SLIDE_DONE = 'UPLOAD_PRESENTATION_SLIDE_DONE';
export const UPLOAD_PRESENTATION_SLIDE_CANCEL = 'UPLOAD_PRESENTATION_SLIDE_CANCEL';
export const UPLOAD_PRESENTATION_SLIDE_CANCEL_SUCCESS = 'UPLOAD_PRESENTATION_SLIDE_CANCEL_SUCCESS';
export const UPLOAD_PRESENTATION_SLIDE_ORDER = 'UPLOAD_PRESENTATION_SLIDE_ORDER';

// Document
export const UPLOAD_DOCUMENT_INIT = 'UPLOAD_DOCUMENT_INIT';
export const UPLOAD_DOCUMENT_ADD = 'UPLOAD_DOCUMENT_ADD';
export const UPLOAD_DOCUMENT_ERROR = 'UPLOAD_DOCUMENT_ERROR';
export const UPLOAD_DOCUMENT_CHUNK_SUCCESS = 'UPLOAD_DOCUMENT_CHUNK_SUCCESS';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_PROCESSING = 'UPLOAD_DOCUMENT_PROCESSING';
export const UPLOAD_DOCUMENT_DONE = 'UPLOAD_DOCUMENT_DONE';
export const UPLOAD_DOCUMENT_CANCEL = 'UPLOAD_DOCUMENT_CANCEL';
export const UPLOAD_DOCUMENT_CANCEL_SUCCESS = 'UPLOAD_DOCUMENT_CANCEL_SUCCESS';
export const UPLOAD_DOCUMENT_ORDER = 'UPLOAD_DOCUMENT_ORDER';

// Viewer custom document
export const UPLOAD_VIEWER_CUSTOM_DOCUMENT_INIT = 'UPLOAD_VIEWER_CUSTOM_DOCUMENT_INIT';
export const UPLOAD_VIEWER_CUSTOM_DOCUMENT_ADD = 'UPLOAD_VIEWER_CUSTOM_DOCUMENT_ADD';
export const UPLOAD_VIEWER_CUSTOM_DOCUMENT_ERROR = 'UPLOAD_VIEWER_CUSTOM_DOCUMENT_ERROR';
export const UPLOAD_VIEWER_CUSTOM_DOCUMENT_CHUNK_SUCCESS = 'UPLOAD_VIEWER_CUSTOM_DOCUMENT_CHUNK_SUCCESS';
export const UPLOAD_VIEWER_CUSTOM_DOCUMENT_SUCCESS = 'UPLOAD_VIEWER_CUSTOM_DOCUMENT_SUCCESS';
export const UPLOAD_VIEWER_CUSTOM_DOCUMENT_PROCESSING = 'UPLOAD_VIEWER_CUSTOM_DOCUMENT_PROCESSING';
export const UPLOAD_VIEWER_CUSTOM_DOCUMENT_DONE = 'UPLOAD_VIEWER_CUSTOM_DOCUMENT_DONE';
export const UPLOAD_VIEWER_CUSTOM_DOCUMENT_CANCEL = 'UPLOAD_VIEWER_CUSTOM_DOCUMENT_CANCEL';
export const UPLOAD_VIEWER_CUSTOM_DOCUMENT_CANCEL_SUCCESS = 'UPLOAD_VIEWER_CUSTOM_DOCUMENT_CANCEL_SUCCESS';
export const UPLOAD_VIEWER_CUSTOM_DOCUMENT_ORDER = 'UPLOAD_VIEWER_CUSTOM_DOCUMENT_ORDER';

// Media
export const UPLOAD_COMMERCIAL_VIDEO_INIT = 'UPLOAD_COMMERCIAL_VIDEO_INIT';
export const UPLOAD_COMMERCIAL_VIDEO_ADD = 'UPLOAD_COMMERCIAL_VIDEO_ADD';
export const UPLOAD_COMMERCIAL_VIDEO_SUCCESS = 'UPLOAD_COMMERCIAL_VIDEO_SUCCESS';
export const UPLOAD_COMMERCIAL_VIDEO_ERROR = 'UPLOAD_COMMERCIAL_VIDEO_ERROR';
export const UPLOAD_COMMERCIAL_VIDEO_CHUNK_SUCCESS = 'UPLOAD_COMMERCIAL_VIDEO_CHUNK_SUCCESS';
export const UPLOAD_COMMERCIAL_VIDEO_PROCESSING = 'UPLOAD_COMMERCIAL_VIDEO_PROCESSING';
export const UPLOAD_COMMERCIAL_VIDEO_PROCESSING_ERROR = 'UPLOAD_COMMERCIAL_VIDEO_PROCESSING_ERROR';
export const UPLOAD_COMMERCIAL_VIDEO_DONE = 'UPLOAD_COMMERCIAL_VIDEO_DONE';
export const UPLOAD_COMMERCIAL_VIDEO_CANCEL = 'UPLOAD_COMMERCIAL_VIDEO_CANCEL';
export const UPLOAD_COMMERCIAL_VIDEO_CANCEL_SUCCESS = 'UPLOAD_COMMERCIAL_VIDEO_CANCEL_SUCCESS';
export const UPLOAD_COMMERCIAL_VIDEO_ORDER = 'UPLOAD_COMMERCIAL_VIDEO_ORDER';

// Logo
export const UPLOAD_LOGO_INIT = 'UPLOAD_LOGO_INIT';
export const UPLOAD_LOGO_ADD = 'UPLOAD_LOGO_ADD';
export const UPLOAD_LOGO_CHUNK_SUCCESS = 'UPLOAD_LOGO_CHUNK_SUCCESS';
export const UPLOAD_LOGO_SUCCESS = 'UPLOAD_LOGO_SUCCESS';
export const UPLOAD_LOGO_ERROR = 'UPLOAD_LOGO_ERROR';
export const UPLOAD_LOGO_PROCESSING = 'UPLOAD_LOGO_PROCESSING';
export const UPLOAD_LOGO_DONE = 'UPLOAD_LOGO_DONE';
export const UPLOAD_LOGO_CANCEL = 'UPLOAD_LOGO_CANCEL';
export const UPLOAD_LOGO_CANCEL_SUCCESS = 'UPLOAD_LOGO_CANCEL_SUCCESS';

// Disclaimer Roadshow Logo
export const UPLOAD_DISCLAIMER_ROADSHOW_LOGO_INIT = 'UPLOAD_DISCLAIMER_ROADSHOW_LOGO_INIT';
export const UPLOAD_DISCLAIMER_ROADSHOW_LOGO_ADD = 'UPLOAD_DISCLAIMER_ROADSHOW_LOGO_ADD';
export const UPLOAD_DISCLAIMER_ROADSHOW_LOGO_CHUNK_SUCCESS = 'UPLOAD_DISCLAIMER_ROADSHOW_LOGO_CHUNK_SUCCESS';
export const UPLOAD_DISCLAIMER_ROADSHOW_LOGO_SUCCESS = 'UPLOAD_DISCLAIMER_ROADSHOW_LOGO_SUCCESS';
export const UPLOAD_DISCLAIMER_ROADSHOW_LOGO_ERROR = 'UPLOAD_DISCLAIMER_ROADSHOW_LOGO_ERROR';
export const UPLOAD_DISCLAIMER_ROADSHOW_LOGO_PROCESSING = 'UPLOAD_DISCLAIMER_ROADSHOW_LOGO_PROCESSING';
export const UPLOAD_DISCLAIMER_ROADSHOW_LOGO_DONE = 'UPLOAD_DISCLAIMER_ROADSHOW_LOGO_DONE';
export const UPLOAD_DISCLAIMER_ROADSHOW_LOGO_CANCEL = 'UPLOAD_DISCLAIMER_ROADSHOW_LOGO_CANCEL';
export const UPLOAD_DISCLAIMER_ROADSHOW_LOGO_CANCEL_SUCCESS = 'UPLOAD_DISCLAIMER_ROADSHOW_LOGO_CANCEL_SUCCESS';

// Disclaimer Background Image
export const UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_INIT = 'UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_INIT';
export const UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_ADD = 'UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_ADD';
export const UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_CHUNK_SUCCESS = 'UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_CHUNK_SUCCESS';
export const UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_SUCCESS = 'UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_SUCCESS';
export const UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_ERROR = 'UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_ERROR';
export const UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_PROCESSING = 'UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_PROCESSING';
export const UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_DONE = 'UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_DONE';
export const UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_CANCEL = 'UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_CANCEL';
export const UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_CANCEL_SUCCESS = 'UPLOAD_DISCLAIMER_ROADSHOW_BACKGROUND_IMAGE_CANCEL_SUCCESS';

// Presentation media audio
export const UPLOAD_PRESENTATION_MEDIA_AUDIO_INIT = 'UPLOAD_PRESENTATION_MEDIA_AUDIO_INIT';
export const UPLOAD_PRESENTATION_MEDIA_AUDIO_ADD = 'UPLOAD_PRESENTATION_MEDIA_AUDIO_ADD';
export const UPLOAD_PRESENTATION_MEDIA_AUDIO_CHUNK_SUCCESS = 'UPLOAD_PRESENTATION_MEDIA_AUDIO_CHUNK_SUCCESS';
export const UPLOAD_PRESENTATION_MEDIA_AUDIO_SUCCESS = 'UPLOAD_PRESENTATION_MEDIA_AUDIO_SUCCESS';
export const UPLOAD_PRESENTATION_MEDIA_AUDIO_ERROR = 'UPLOAD_PRESENTATION_MEDIA_AUDIO_ERROR';
export const UPLOAD_PRESENTATION_MEDIA_AUDIO_PROCESSING = 'UPLOAD_PRESENTATION_MEDIA_AUDIO_PROCESSING';
export const UPLOAD_PRESENTATION_MEDIA_AUDIO_DONE = 'UPLOAD_PRESENTATION_MEDIA_AUDIO_DONE';
export const UPLOAD_PRESENTATION_MEDIA_AUDIO_CANCEL = 'UPLOAD_PRESENTATION_MEDIA_AUDIO_CANCEL';
export const UPLOAD_PRESENTATION_MEDIA_AUDIO_CANCEL_SUCCESS = 'UPLOAD_PRESENTATION_MEDIA_AUDIO_CANCEL_SUCCESS';

// Presentation media video
export const UPLOAD_PRESENTATION_MEDIA_VIDEO_INIT = 'UPLOAD_PRESENTATION_MEDIA_VIDEO_INIT';
export const UPLOAD_PRESENTATION_MEDIA_VIDEO_ADD = 'UPLOAD_PRESENTATION_MEDIA_VIDEO_ADD';
export const UPLOAD_PRESENTATION_MEDIA_VIDEO_CHUNK_SUCCESS = 'UPLOAD_PRESENTATION_MEDIA_VIDEO_CHUNK_SUCCESS';
export const UPLOAD_PRESENTATION_MEDIA_VIDEO_SUCCESS = 'UPLOAD_PRESENTATION_MEDIA_VIDEO_SUCCESS';
export const UPLOAD_PRESENTATION_MEDIA_VIDEO_ERROR = 'UPLOAD_PRESENTATION_MEDIA_VIDEO_ERROR';
export const UPLOAD_PRESENTATION_MEDIA_VIDEO_PROCESSING = 'UPLOAD_PRESENTATION_MEDIA_VIDEO_PROCESSING';
export const UPLOAD_PRESENTATION_MEDIA_VIDEO_DONE = 'UPLOAD_PRESENTATION_MEDIA_VIDEO_DONE';
export const UPLOAD_PRESENTATION_MEDIA_VIDEO_CANCEL = 'UPLOAD_PRESENTATION_MEDIA_VIDEO_CANCEL';
export const UPLOAD_PRESENTATION_MEDIA_VIDEO_CANCEL_SUCCESS = 'UPLOAD_PRESENTATION_MEDIA_VIDEO_CANCEL_SUCCESS';
export const UPLOAD_PRESENTATION_MEDIA_VIDEO_PROCESSING_STATUS_UPDATE = 'UPLOAD_PRESENTATION_MEDIA_VIDEO_PROCESSING_STATUS_UPDATE';

// Presentation media audio
export const UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_INIT = 'UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_INIT';
export const UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_ADD = 'UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_ADD';
export const UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_CHUNK_SUCCESS = 'UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_CHUNK_SUCCESS';
export const UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_SUCCESS = 'UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_SUCCESS';
export const UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_ERROR = 'UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_ERROR';
export const UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_PROCESSING = 'UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_PROCESSING';
export const UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_DONE = 'UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_DONE';
export const UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_CANCEL = 'UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_CANCEL';
export const UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_CANCEL_SUCCESS = 'UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_CANCEL_SUCCESS';

// related roadshow logo
export const UPLOAD_RELATED_ROADSHOW_LOGO_INIT = 'UPLOAD_RELATED_ROADSHOW_LOGO_INIT';
export const UPLOAD_RELATED_ROADSHOW_LOGO_ADD = 'UPLOAD_RELATED_ROADSHOW_LOGO_ADD';
export const UPLOAD_RELATED_ROADSHOW_LOGO_CHUNK_SUCCESS = 'UPLOAD_RELATED_ROADSHOW_LOGO_CHUNK_SUCCESS';
export const UPLOAD_RELATED_ROADSHOW_LOGO_SUCCESS = 'UPLOAD_RELATED_ROADSHOW_LOGO_SUCCESS';
export const UPLOAD_RELATED_ROADSHOW_LOGO_ERROR = 'UPLOAD_RELATED_ROADSHOW_LOGO_ERROR';
export const UPLOAD_RELATED_ROADSHOW_LOGO_PROCESSING = 'UPLOAD_RELATED_ROADSHOW_LOGO_PROCESSING';
export const UPLOAD_RELATED_ROADSHOW_LOGO_DONE = 'UPLOAD_RELATED_ROADSHOW_LOGO_DONE';
export const UPLOAD_RELATED_ROADSHOW_LOGO_CANCEL = 'UPLOAD_RELATED_ROADSHOW_LOGO_CANCEL';
export const UPLOAD_RELATED_ROADSHOW_LOGO_CANCEL_SUCCESS = 'UPLOAD_RELATED_ROADSHOW_LOGO_CANCEL_SUCCESS';

// Presentation cue points
export const UPLOAD_PRESENTATION_CUE_POINTS_LIST_INIT = 'UPLOAD_PRESENTATION_CUE_POINTS_LIST_INIT';
export const UPLOAD_PRESENTATION_CUE_POINTS_LIST_ADD = 'UPLOAD_PRESENTATION_CUE_POINTS_LIST_ADD';
export const UPLOAD_PRESENTATION_CUE_POINTS_LIST_CHUNK_SUCCESS = 'UPLOAD_PRESENTATION_CUE_POINTS_LIST_CHUNK_SUCCESS';
export const UPLOAD_PRESENTATION_CUE_POINTS_LIST_SUCCESS = 'UPLOAD_PRESENTATION_CUE_POINTS_LIST_SUCCESS';
export const UPLOAD_PRESENTATION_CUE_POINTS_LIST_ERROR = 'UPLOAD_PRESENTATION_CUE_POINTS_LIST_ERROR';
export const UPLOAD_PRESENTATION_CUE_POINTS_LIST_PROCESSING = 'UPLOAD_PRESENTATION_CUE_POINTS_LIST_PROCESSING';
export const UPLOAD_PRESENTATION_CUE_POINTS_LIST_DONE = 'UPLOAD_PRESENTATION_CUE_POINTS_LIST_DONE';
export const UPLOAD_PRESENTATION_CUE_POINTS_LIST_CANCEL = 'UPLOAD_PRESENTATION_CUE_POINTS_LIST_CANCEL';
export const UPLOAD_PRESENTATION_CUE_POINTS_LIST_CANCEL_SUCCESS = 'UPLOAD_PRESENTATION_CUE_POINTS_LIST_CANCEL_SUCCESS';
