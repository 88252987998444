import { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { JSONTree } from 'react-json-tree';
import { Modal } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';

interface IProps {
  isFetching: boolean,
  depthData?: number,
  getList: () => void,
  resetState: () => void,
  title?: string,
  classNameHoverSelectPath?: string,
  buttonText?: string,
  depthDataName?: string,
  path: { [key: string]: any }[],
  changePath: (path: string) => void,
  changePathData: (path: { [key: string]: any }) => void,
  structure: { [key: string]: any },
}

const defaultProps = {
  depthData: 1,
  depthDataName: 'SELECT TABLE',
  title: 'Databases structure',
  buttonText: 'Open Structure Databases',
  classNameHoverSelectPath: 'is-hover',
};

class DataSourceStructureModal extends Component<IProps, { isVisible: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.getTitle = this.getTitle.bind(this);
    this.getStructure = this.getStructure.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.getStructureData = this.getStructureData.bind(this);
  }

  componentDidMount() {
    this.getList();
  }

  componentDidUpdate(prevProps) {
    if (
      (
        !isEqual(this.props.structure, prevProps.structure)
        && this.props.path.length > 0
      )
      ||
      (
        !isEqual(this.props.structure, prevProps.structure)
        && this.props.path.length > 0
        && Object.keys(this.props.structure).length > 0
      )
    ) {
      this.onSelect(
        this.props.path,
        this.props.structure,
      );
    }

    if (prevProps.path.length > 0 && this.props.path.length === 0) {
      this.props.resetState();
      this.getList();
    }
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  onOpen() {
    this.setState({
      isVisible: true,
    });
  }

  onClose() {
    this.setState({
      isVisible: false,
    });
  }

  onSelect(path, pathToValue = this.getStructureData()) {
    pathToValue = { ...pathToValue };
    path.forEach((item) => {
      pathToValue = pathToValue[item];
    });

    this.props.changePath(path);
    this.props.changePathData(pathToValue);
    this.onClose();
  }

  getList() {
    this.props.getList();
  }

  getTitle() {
    return this.props.title;
  }

  getStructure() {
    let maxDepth = this.props.depthData;
    let { depthDataName } = this.props;
    let getItemOfMaxDepth = (obj, level = 0) => {
      let returnObj = {};
      let k;

      if (obj instanceof Object) {
        // eslint-disable-next-line no-restricted-syntax
        for (k in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, k)) {
            if (level > maxDepth) {
              returnObj[k] = depthDataName;
            } else {
              returnObj[k] = getItemOfMaxDepth(obj[k], level + 1);
            }
          }
        }
      } else {
        returnObj = obj;
      }
      return returnObj;
    };
    return getItemOfMaxDepth(this.getStructureData());
  }

  getStructureData() {
    return this.props.structure;
  }

  isVisible() {
    return this.state.isVisible;
  }

  handleOnClick(rest) {
    let path = rest.reverse();
    path.pop();
    this.onSelect(path);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const { classNameHoverSelectPath, buttonText } = this.props;
    return (
      <div className="data-source-structure-modal">
        <Modal
          onCloseClicked={ () => this.onClose() }
          isVisible={ this.isVisible() }
          title={ this.getTitle() }
          dataTest="databaseStructureModal"
          footer={ (
            <Button
              variant={ variantTypes.secondary }
              onClick={ () => this.onClose() }
              title="Close"
              dataTest="closeButton"
            />
          ) }
        >
          { this.isVisible() && (
            <JSONTree
              data={ this.getStructure() }
              shouldExpandNodeInitially={ (keyName, data, level) => level === 1 }
              hideRoot
              invertTheme={ false }
              valueRenderer={ (raw: React.ReactNode, ...rest) => {
                let ref = null;
                let showCursor = () => {
                  ref.classList.toggle(classNameHoverSelectPath);
                };
                return (
                  <em
                    ref={ (node) => { ref = node; } }
                    onClick={ (e) => {
                      // @ts-ignore
                      this.handleOnClick(rest.slice(0), e);
                    } }
                    onMouseEnter={ showCursor }
                    onMouseLeave={ showCursor }
                  >{ raw }
                  </em>
                );
              } }
            />
          ) }
        </Modal>
        <Button
          variant={ variantTypes.success }
          disabled={ this.props.isFetching }
          onClick={ this.onOpen }
          title={ buttonText }
          dataTest="openButton"
        />
      </div>
    );
  }
}

// @ts-ignore
DataSourceStructureModal.defaultProps = defaultProps;

export default DataSourceStructureModal;
