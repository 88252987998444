import { Component } from 'react';
import { Field, FieldArray } from 'redux-form';
import cn from 'classnames';
import Select from '@/Framework/UI/Molecules/Form/Select/legacy';
import NotificationManager from '@/Framework/Notification/NotificationManager';
import Groups from '../Groups';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Input, Icon, IconType } from '@dealroadshow/uikit';

import styles from './forms.scss';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import spaceStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';

interface IProps {
  fields: { [key: string]: any },
  logicOperators: { [key: string]: any }[],
  onFormChange: (name: string, value: string | number) => void,
  onFormArray: { [key: string]: any },
  conditionalOperators: { [key: string]: any }[],
}

class Forms extends Component<IProps> {
  constructor(props) {
    super(props);
    this.renderFormAddNewGroup = this.renderFormAddNewGroup.bind(this);
    this.onHandleAddNewGroup = this.onHandleAddNewGroup.bind(this);
    this.handleRemoveForm = this.handleRemoveForm.bind(this);
  }

  onHandleAddNewGroup(formName, formIndex) {
    let form = this.props.fields.get(formIndex);
    let value = form.newGroup;
    if (!value) {
      NotificationManager.error('Can not be empty');
      return;
    }
    let unique = form.groups.filter((group) => group.label === value);
    if (unique.length) {
      NotificationManager.error(`[${ value }] exist in group list`);
      return;
    }
    this.props.onFormArray.push(`${ formName }.groups`, {
      lo: this.props.logicOperators[0].value,
      label: value,
      children: [],
    });
    this.props.onFormChange(`${ formName }.newGroup`, '');
  }

  handleRemoveForm(formIndex) {
    this.props.fields.remove(formIndex);
    let newActiveForm = this.props.fields.length > 1 ? this.props.fields.length - 2 : null;
    this.props.onFormChange('activeForm', newActiveForm);
  }

  renderFormAddNewGroup(formName, formIndex) {
    return (
      <div>
        <div>
          <Button
            variant={ variantTypes.action }
            onClick={ () => {
              this.onHandleAddNewGroup(formName, formIndex);
            } }
            title="Add group"
            dataTest="queryFilterFormAddGroupButton"
          />
        </div>
        <Field
          name={ `${ formName }.newGroup` }
          placeholder="like: id, name... Need be unique"
          component={ Input }
          dataTest={ `${ formName }NewGroupInput` }
        />
      </div>
    );
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return this.props.fields.map((formName, formIndex) => {
      let form = this.props.fields.get(formIndex);

      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={ formIndex }>
          { formIndex > 0 && (
            <div className={ styles.fieldsFormLoWrapper }>
              <Field
                name={ `${ formName }.lo` }
                component={ (fieldProps) => (
                  <Select
                    selectClassName="drop-up"
                    label={ fieldProps.label }
                    name={ fieldProps.input.name }
                    value={ fieldProps.input.value }
                    onChange={ fieldProps.input.onChange }
                    onBlur={ () => fieldProps.input.onBlur(fieldProps.input.value) }
                    options={ this.props.logicOperators }
                    simpleValue
                    dataTest="condorTechnicalSupportQueryFilterFormSelect"
                  />
                ) }
              />
            </div>
          ) }
          <div className={ cn(cardStyles.cardContainer, cardStyles.cardInner) }>
            <Icon
              type={ IconType.trash }
              className={ styles.fieldTrashIcon }
              onClick={ () => this.handleRemoveForm(formIndex) }
            />
            <div>{ `${ form.label }: ${ formIndex }` } </div>
            <div>
              <FieldArray
                name={ `${ formName }.groups` }
                component={ Groups }
                logicOperators={ this.props.logicOperators }
                conditionalOperators={ this.props.conditionalOperators }
                onFormChange={ this.props.onFormChange }
                onFormArray={ this.props.onFormArray }
              />
            </div>
            <div className={ spaceStyles.mbm } />
            { this.renderFormAddNewGroup(formName, formIndex) }
          </div>
        </div>
      );
    });
  }
}

export default Forms;
