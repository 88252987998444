import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as constants from '@/Framework/UI/Organisms/Upload/constants';
import { UploadOneFile } from '../components';
import { uploadPresentationMediaAudio, uploadPresentationMediaAudioCancel } from '../actions';

function mapStateToProps(state) {
  return {
    uploadFile: state.upload.presentationMediaAudio,
    allowedFileTypes: constants.UPLOAD_PRESENTATION_MEDIA_AUDIO_ALLOWED_FILES,
    allowedFileText: constants.UPLOAD_PRESENTATION_MEDIA_AUDIO_ALLOWED_FILES_TEXT,
    formFieldName: 'media',
    uploadName: constants.UPLOAD_PRESENTATION_MEDIA_AUDIO_TEXT_NAME,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUpload: uploadPresentationMediaAudio,
    onUploadCancel: uploadPresentationMediaAudioCancel,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadOneFile);
