export const GET_BONDS_FILTER = 'GET_BONDS_FILTER';
export const GET_BONDS_FILTER_SUCCESS = 'GET_BONDS_FILTER_SUCCESS';
export const GET_BONDS_FILTER_ERROR = 'GET_BONDS_FILTER_ERROR';

export const GET_BONDS_FILTER_BY_HASH = 'GET_BONDS_FILTER_BY_HASH';
export const GET_BONDS_FILTER_BY_HASH_SUCCESS = 'GET_BONDS_FILTER_BY_HASH_SUCCESS';
export const GET_BONDS_FILTER_BY_HASH_ERROR = 'GET_BONDS_FILTER_BY_HASH_ERROR';

export const BONDS_FILTER_PAYLOAD = 'BONDS_FILTER_PAYLOAD';
export const BONDS_FILTER_APPLY = 'BONDS_FILTER_APPLY';
export const BONDS_INITIAL_FILTERS = 'BONDS_INITIAL_FILTERS';
export const BONDS_FILTER_MIN_DATE_FROM = 'BONDS_FILTER_MIN_DATE_FROM';
export const BONDS_FILTER_MAX_DATE_TO = 'BONDS_FILTER_MAX_DATE_TO';
export const RESET_BONDS_FILTER_MIN_MAX_DATE = 'RESET_BONDS_FILTER_MIN_MAX_DATE';

export const RESET_BONDS_FILTER = 'RESET_BONDS_FILTER';
