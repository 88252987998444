import React, { Component } from 'react';
import config from '@/Framework/config';

interface IProps {
  setBreadcrumb: (
    option: {
      name: string,
      url: string,
    }
  ) => void,
  removeBreadcrumb: (breadcrumb: string) => void,
  children: React.ReactNode,
}

class TechnicalSupport extends Component<IProps> {
  constructor(props) {
    super(props);
    this.props.setBreadcrumb({
      name: 'Technical Support',
      url: `${ config.tenant.condor.routes.base }/technical`,
    });
  }

  componentWillUnmount() {
    this.props.removeBreadcrumb(`${ config.tenant.condor.routes.base }/technical`);
  }

  render() {
    return this.props.children;
  }
}

export default TechnicalSupport;
