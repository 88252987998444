export const BONDS_SEARCH_CHANGE = 'BONDS_SEARCH_CHANGE';
export const BONDS_SET_ASSET_CLASS = 'BONDS_SET_ASSET_CLASS';
export const BONDS_RESET_METADATA = 'BONDS_RESET_METADATA';

export const GET_BONDS_EXPORT = 'GET_BONDS_EXPORT';
export const GET_BONDS_EXPORT_SUCCESS = 'GET_BONDS_EXPORT_SUCCESS';
export const GET_BONDS_EXPORT_FAILURE = 'GET_BONDS_EXPORT_FAILURE';

export const GET_BONDS_ASSET_CLASSES = 'GET_BONDS_ASSET_CLASSES';
export const GET_BONDS_ASSET_CLASSES_SUCCESS = 'GET_BONDS_ASSET_CLASSES_SUCCESS';
export const GET_BONDS_ASSET_CLASSES_FAILURE = 'GET_BONDS_ASSET_CLASSES_FAILURE';

export const SET_BOND_TOTAL_COUNT = 'SET_BOND_TOTAL_COUNT';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const SET_BOND_ACCESS_STATUS = 'SET_BOND_ACCESS_STATUS';
export const RESET_BOND_ACCESS = 'RESET_BOND_ACCESS';
