import { v4 as uuid } from 'uuid';
import * as actionTypes from '../actionTypes';
import * as uploadTypes from '../uploadTypes';

const initialState = {
  prevPresentation: null,
  presentationOrigin: null,
  slideShow: [],
  process: {
    progress: 0,
    status: '',
    uuid: '',
  },
  preview: {
    url: '',
  },
  uploadFile: null,
  callback: null,
  name: '',
  uploaded: false,
  canSave: false,
};

export default function presentation(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPLOAD_PRESENTATION_ADD:
      let prevPresentation = {
        presentationOrigin: state.presentationOrigin,
        slideShow: state.slideShow,
        preview: state.preview,
        uploadFile: state.uploadFile,
        callback: state.callback,
        name: state.presentationOrigin ? state.presentationOrigin.name : '',
        process: {
          progress: 100,
          status: uploadTypes.UPLOAD_DONE,
          uuid: action.payload.uuid,
        },
        uploaded: true,
        canSave: true,
      };
      return {
        prevPresentation: state.presentationOrigin ? prevPresentation : null,
        presentationOrigin: {},
        slideShow: [],
        preview: {
          url: '',
        },
        process: {
          progress: 0,
          status: uploadTypes.UPLOAD_START,
          uuid: action.payload.uuid,
        },
        uploadFile: action.payload.uploadFile,
        callback: action.payload.callback,
        name: action.payload.uploadFile.name,
        uploaded: false,
        canSave: false,
      };

    case actionTypes.UPLOAD_PRESENTATION_CHUNK_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            progress: parseInt(action.payload.progress),
            uuid: action.payload.uuid,
            status: uploadTypes.UPLOAD_CHUNK_SUCCESS,
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            status: uploadTypes.UPLOAD_SUCCESS,
          },
          uploaded: true,
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_ERROR:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            status: uploadTypes.UPLOAD_ERROR,
          },
          uploaded: false,
          canSave: false,
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_PROCESSING:
      if (state.process.uuid === action.payload.uuid &&
          state.process.status !== uploadTypes.UPLOAD_DONE) {
        return {
          ...state,
          process: {
            ...state.process,
            progress: 0,
            status: uploadTypes.UPLOAD_PROCESSING_START,
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_DONE:
      if (state.process.uuid === action.payload.uuid) {
        let primaryJpegSlides = action.payload.data.jpeg;
        return {
          ...state,
          process: {
            ...state.process,
            progress: 100,
            status: uploadTypes.UPLOAD_DONE,
          },
          presentationOrigin: {
            url: `/${ action.payload.data.pdf[0] }`,
            name: state.uploadFile.name,
          },
          slideShow: primaryJpegSlides.map((slide, index) => {
            let urls = {
              jpeg: `/${ slide }`,
            };
            if (action.payload.data.svg && action.payload.data.svg[index]) {
              urls.svg = `/${ action.payload.data.svg[index] }`;
            }
            if (action.payload.data.png && action.payload.data.png[index]) {
              urls.png = `/${ action.payload.data.png[index] }`;
            }
            return { urls };
          }),
          preview: {
            url: `/${ action.payload.data.jpeg[0].replace('/slideshow', '/preview/slideshow') }`,
          },
          uploaded: true,
          canSave: true,
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_CANCEL_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return initialState;
      }
      return state;

    case actionTypes.UPLOAD_CLEAR:
      return initialState;

    case actionTypes.UPLOAD_PRESENTATION_INIT:
      let preview = '';
      if (action.payload.slideShow.length) {
        preview = action.payload.slideShow[0].urls.jpeg.replace('/slideshow', '/preview/slideshow');
      }
      return {
        prevPresentation: null,
        presentationOrigin: action.payload.origin,
        slideShow: action.payload.slideShow,
        preview: {
          url: preview,
        },
        process: {
          progress: 100,
          status: uploadTypes.UPLOAD_DONE,
          uuid: uuid(),
        },
        uploadFile: null,
        callback: null,
        name: '',
        uploaded: true,
        canSave: true,
      };

    default:
      return state;
  }
}
