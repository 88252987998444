import cn from 'classnames';
import noop from 'lodash/noop';
import React, { useEffect } from 'react';
import { Spinner, ImgFetching, ProgressBar, Icon, IconType } from '@dealroadshow/uikit';
import files from '@/Framework/url/filesUrl';

import {
  convertUploadTypesToString,
  isShowProgressByUploadType,
} from '../helpers/convertUploadTypes';
import UploadDropzoneOneFile from './UploadDropzoneOneFile';
import styles from '../upload.scss';

export interface IProps {
  urlHelper?: { [key: string]: any },
  isShowError?: boolean,
  uploadFile?: { [key: string]: any },
  formFieldName?: string,
  uploadForType?: string,
  onUpload?: (acceptedFiles: any, onUploadProcessingDone: any) => void,
  onUploadCancel?: (e: any) => void,
  onUploadProcessingDone?: () => void,
  onChange?: (e: any) => void,
  allowedFileTypes?: string,
  allowedFileText?: string,
  uploadName?: string,
  uuid?: string,
  slideType?: string,
  canRemove?: boolean,
  maxSize?: number,
  maxSizeUnits?: string,
  disabled?: boolean,
  disabledText?: string,
  disabledComponent?: React.ElementType,
  description?: string,
  maxSizeErrorMessage?: string,
  dropzoneClassName?: string,
  uploadPreviewClassName?: string,
  previewNameClassName?: string,
}

const UploadPreview = ({
  urlHelper,
  isShowError = false,
  uploadFile = {},
  formFieldName,
  uploadForType = 'other',
  onUpload,
  onUploadCancel,
  onUploadProcessingDone,
  onChange = noop,
  allowedFileTypes,
  allowedFileText,
  uploadName,
  uuid = '',
  slideType = '',
  canRemove = true,
  maxSize = Infinity,
  maxSizeUnits = 'B',
  disabled,
  disabledText,
  disabledComponent: DisabledComponent,
  description,
  maxSizeErrorMessage = '',
  dropzoneClassName = '',
  uploadPreviewClassName = '',
  previewNameClassName = '',
}: IProps) => {
  // Prevent PDF file caching when canceling and creating new DRS
  useEffect(() => {
   return () => {
     if (uploadForType !== 'slide') {
       handleRemove();
     }
   };
  }, []);

  const getFile = () => {
    if (uploadForType === 'other') {
      return uploadFile;
    }
    if (uploadForType === 'slide') {
      return uploadFile[uuid].types[slideType];
    }

    return undefined;
  };

  const getFilePreviewUrl = (params) => {
    if (urlHelper) {
      return urlHelper.getFileUrl(params);
    }
    return files.getFileUrl(params);
  };

  const handleUpload = (acceptedFiles) => {
    if (uploadForType === 'other') {
      onUpload(acceptedFiles, onUploadProcessingDone);
    }
    if (uploadForType === 'slide') {
      onChange(uuid);
      onUpload({
        files: acceptedFiles,
        uuidOriginal: uuid,
        slideType,
      }, onUploadProcessingDone);
    }
  };

  const handleRemove = () => {
    if (uploadForType === 'other') {
      onUploadCancel(getFile().process.uuid);
    }
    if (uploadForType === 'slide') {
      onUploadCancel({
        uuid: getFile().process.uuid,
        uuidOriginal: uuid,
        slideType,
      });
    }
  };

  const file = getFile();

  const dropZoneCls = cn(
    dropzoneClassName,
    { [styles.isDropZoneError]: isShowError && !file.canSave && !file.process.uuid },
  );

  if (disabled) {
    if (DisabledComponent) {
      return <DisabledComponent />;
    }
    return (
      <div className={ styles.disabled }>
        <div className={ styles.disabledText }>
          { disabledText }
        </div>
      </div>
    );
  }

  return (
    <div className={ styles.formFileUpload }>
      { !!file.process.uuid && (
        <div className={ cn(styles.preview, uploadPreviewClassName) } data-test="uploadPreview">
          { canRemove && (
            <span
              className={ styles.previewRemoveIcon }
              onClick={ handleRemove }
            >
              <Icon type={ IconType.close } />
            </span>
          ) }
          { !file.preview.url && (
            <span className={ cn(styles.previewName, previewNameClassName) }>
              { file.name }
            </span>
          ) }
          { !file.preview.url && (
            <Spinner overlay />
          ) }

          { !file.preview.url && (
            <span className={ styles.previewStatus }>
              { convertUploadTypesToString(file.process.status) }
            </span>
          ) }
          { isShowProgressByUploadType(file.process.status) && (
            <ProgressBar
              className={ styles.previewProgressBar }
              filledClassName={ styles.previewProgressBarFilled }
              value={ file.process.progress }
            />
          ) }
          { !!file.preview.url && (
            <ImgFetching
              className={ styles.previewImg }
              src={ getFilePreviewUrl(file.preview.url) }
              spinnerProps={ {
                overlay: true,
              } }
            />
          ) }
        </div>
      ) }
      <div className={ styles.dropZoneWrp } data-test="uploadOneFileDropzone">
        <UploadDropzoneOneFile
          onUpload={ handleUpload }
          allowedFileTypes={ allowedFileTypes }
          allowedFileText={ allowedFileText }
          uploadName={ uploadName }
          maxSize={ maxSize }
          maxSizeUnits={ maxSizeUnits }
          formFieldName={ formFieldName }
          dropzoneHelperClassName={ dropZoneCls }
          maxSizeErrorMessage={ maxSizeErrorMessage }
        />
        { description && (
          <div className={ cn(styles.tip, styles.videoTip) }>
            { description }
          </div>
        ) }
      </div>
    </div>
  );
};

export default UploadPreview;
