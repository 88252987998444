import { Component } from 'react';
import { JSONTree } from 'react-json-tree';
import { Modal } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';

interface IProps {
  onClose: () => void,
  onApply: () => void,
  showItem: { [key: string]: any },
}

class QueryFilterPrepareModal extends Component<IProps> {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.onApply = this.onApply.bind(this);
    this.getItem = this.getItem.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  onApply() {
    this.props.onApply();
    this.onClose();
  }

  getItem() {
    return this.props.showItem;
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <Modal
        onCloseClicked={ () => this.onClose() }
        isVisible
        title="json query field"
        dataTest="queryFilterFormModal"
        footer={ (
          <>
            <Button
              variant={ variantTypes.secondary }
              title="Close"
              dataTest="closeButton"
            />
            <Button
              variant={ variantTypes.action }
              onClick={ () => this.onApply() }
              title="Run query"
              dataTest="runQueryButton"
            />
          </>
        ) }
      >
        <JSONTree
          data={ this.getItem() }
          shouldExpandNodeInitially={ (keyName, data, level) => level === 1 }
          hideRoot
          invertTheme={ false }
        />
      </Modal>
    );
  }
}

export default QueryFilterPrepareModal;
