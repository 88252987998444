import { getItemActionTypes } from '@/Framework/State/Redux/itemFactory';

export default {
  ...getItemActionTypes(
    'CONDOR_FINSIGHT_COMPANY_DEAL',
    { withCreate: true, withUpdate: true, withRemove: true },
  ),
  SET_CONDOR_FINSIGHT_COMPANY_DEAL_IS_INLINE_EDITING: 'SET_CONDOR_FINSIGHT_COMPANY_DEAL_IS_INLINE_EDITING',
  SET_CONDOR_FINSIGHT_COMPANY_DEAL_IS_DELETE_MODAL_VISIBLE: 'SET_CONDOR_FINSIGHT_COMPANY_DEAL_IS_DELETE_MODAL_VISIBLE',
  SET_CONDOR_FINSIGHT_COMPANY_DEAL_ID_DOWNLOADING: 'SET_CONDOR_FINSIGHT_COMPANY_DEAL_ID_DOWNLOADING',
};
