import React, { createContext, useContext, useState } from 'react';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useDIContext } from '@/Framework/DI/DIContext';
import ContactRepository from '@/finsight/infrastructure/repository/ContactRepository';

const useRequestCount = () => {
  const [requestCount, setRequestCount] = useState<number>(0);
  const { container } = useDIContext();

  const getRequestCount = async () => {
    try {
        const contactRepository = container.get(ContactRepository);
        const response = await contactRepository.getPendingRequestsCount();
        setRequestCount(response.count);
    } catch (e) {
        setRequestCount(0);
        NotificationManager.error(getErrorMessage(e));
    }
  };

  return {
    requestCount,
    getRequestCount,
    setRequestCount,
  };
};

export const RequestCountContext = createContext<ReturnType<typeof useRequestCount>>(null);

export const useRequestCountContext = () => {
  const context = useContext(RequestCountContext);
  if (!context) {
    throw new Error('useRequestCountContext must be used within a RequestCountContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const RequestCountContextProvider = ({ children }: IProps) => (
  <RequestCountContext.Provider value={ useRequestCount() }>
    { children }
  </RequestCountContext.Provider>
);

export default RequestCountContextProvider;
