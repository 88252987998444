import { Dependencies } from 'constitute';
import FinsightWebRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import { IUserPlanRequestsState } from '@/finsight/application/User/UserPlan/helpers';
import { IRequestServicePayload } from '@/finsight/domain/vo/IRequestServicePayload';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import {
  IManageUserPlanApproveDenyPayload,
  IManagePlansRequestPayload,
  IManagePlansRequestResponse,
  IManagePlansSubscribersResponse,
  IManagePlansSubscribersPayload,
  IManagePlansManageSubscriberPayload,
  IAddSubscribersPayload,
} from '@/condor/domain/vo/FinsightManager/ManagePlans';
import { IUserPlanResponse } from '@/users/domain/vo/IUserPlanResponse';
import { UserPlanType } from '@/users/domain/UserPlan';

@Dependencies(FinsightWebRpcDispatcher)
class ContactRepository {
  constructor(protected rpc: typeof FinsightWebRpcDispatcher) {}

  getUserCurrentPlan = async (payload = {}): Promise<IUserPlanResponse> => {
    const request = new Request('finsight_web.contact.subscription.get_current_plan', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getUserPlanById = async (payload: { contactId: string }) => {
    const request = new Request('finsight_web.contact.subscription.get_current_plan_by_contact_id', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getSubscriptionRequests = async (payload = {}): Promise<IUserPlanRequestsState> => {
    const request = new Request('finsight_web.contact.subscription.get_requests', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getSubscribersList = async (payload: IManagePlansSubscribersPayload): Promise<IManagePlansSubscribersResponse> => {
    const request = new Request('finsight_web.contact.subscription.get_subscribers', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  updateUserSubscription = async (payload: IManagePlansManageSubscriberPayload) => {
    const request = new Request('finsight_web.contact.subscription.manage_subscription', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getCondorSubscriptionRequests = async (payload: IManagePlansRequestPayload): Promise<IManagePlansRequestResponse> => {
    const response = await this.rpc.call<RpcSuccess>(
      new Request('finsight_web.contact.subscription.manager.get_subscription_requests', payload),
    );
    return response.getResult().payload;
  };

  approveUserRequest = async (payload: IManageUserPlanApproveDenyPayload) => {
    const response = await this.rpc.call<RpcSuccess>(
      new Request('finsight_web.contact.subscription.approve_subscription_request', payload),
    );
    return response.getResult().payload;
  };

  denyUserRequest = async (payload: IManageUserPlanApproveDenyPayload) => {
    const response = await this.rpc.call<RpcSuccess>(
      new Request('finsight_web.contact.subscription.deny_subscription_request', payload),
    );
    return response.getResult().payload;
  };

  requestService = async (payload: IRequestServicePayload) => {
    let response = await this.rpc.call<RpcSuccess>(new Request(
      'finsight_web.contact.subscription.request_service',
      payload,
    ));

    return response.getResult().payload;
  };

  getPendingRequestsCount = async ():Promise<{ count: number }> => {
    const response = await this.rpc.call<RpcSuccess>(
      new Request('finsight_web.contact.subscription.get_pending_request_count'),
    );
    return response.getResult().payload;
  };

  addSubscribersByAdmin = async (payload: IAddSubscribersPayload): Promise<null> => {
    const request = new Request('finsight_web.contact.subscription.add_subscribers', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  checkEmailsStatus = async (payload: { emails: string[] }):Promise<null> => {
    const request = new Request('finsight_web.contact.subscription.check_subscribers_status', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getTeamsByName = async (payload: { name: string }): Promise<{ id: string, name: string }[]> => {
    const request = new Request('finsight_web.contact.subscription.find_teams_by_name', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  subscriptionRequest = async (payload: { plan: UserPlanType }): Promise<null> => {
    const request = new Request('finsight_web.contact.subscription.create_subscription_request', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default ContactRepository;
