import * as actionTypes from './actionTypes';

const initialState = {};

export default function filterReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FILTERS_INITIALIZE:
      if (action.payload.filterName) {
        return {
          ...state,
          [action.payload.filterName]: {},
        };
      }
      return state;
    case actionTypes.FILTERS_UPDATE:
      if (action.payload.filterName) {
        return {
          ...state,
          [action.payload.filterName]: {
            ...state[action.payload.filterName],
            ...action.payload.filters,
          },
        };
      }
      return state;
    case actionTypes.FILTER_BY_CHANGE:
      if (action.payload.filterName) {
        if (action.payload.ids.length) {
          let newState = state[action.payload.filterName][action.payload.filterBy].slice(0);
          action.payload.ids.forEach((id) => {
            if (!state[action.payload.filterName][action.payload.filterBy].includes(id)) {
              newState.push(id);
            } else {
              newState = newState.filter((id) => !action.payload.ids.includes(id));
            }
          });
          return {
            ...state,
            [action.payload.filterName]: {
              ...state[action.payload.filterName],
              [action.payload.filterBy]: newState.sort(),
            },
          };
        }
      }
      return state;
    case actionTypes.FILTERS_RESET:
      if (action.payload.filterName) {
        let newState = { ...state };
        delete newState[action.payload.filterName];
        return newState;
      }
      return state;
    default:
      return state;
  }
}
