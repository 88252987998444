import React from 'react';
import cn from 'classnames';

import styles from '@/Framework/UI/Organisms/Upload/upload.scss';
import linkStyles from '@dealroadshow/uikit/dist/lib/styles/links.scss';

export interface IDropzoneInternalFieldDefaultProps {
  uploadName: string,
  allowedFileText: string,
  fileName: string,
  disabled: boolean,
  children: React.ReactNode,
}

const DropzoneInternalFieldDefault = (props: IDropzoneInternalFieldDefaultProps) => (
  <>
    <div className={ cn(styles.text, { [styles.isDisabled]: props.disabled }) }>
      Drag & drop { props.uploadName } or <span className={ linkStyles.link }>select file</span>
    </div>
    { props.fileName && <span className={ styles.formFileUploadOneFileName }>{ props.fileName } </span> }
    <div className={ styles.tip }>{ props.allowedFileText }</div>
    { props.children }
  </>
);

export default DropzoneInternalFieldDefault;
