import { getItemReducer } from '@/Framework/State/Redux/itemFactory';
import actionTypes from './actionTypes';

const newState = {
  isInlineEditing: false,
  isDeleteModalVisible: false,
  dealIdDownloading: null,
};

export default getItemReducer(
  actionTypes,
  newState,
  {
    [actionTypes.SET_CONDOR_FINSIGHT_COMPANY_DEAL_IS_INLINE_EDITING]: (state, action) => (
      { ...state, isInlineEditing: action.payload }
    ),
    [actionTypes.SET_CONDOR_FINSIGHT_COMPANY_DEAL_IS_DELETE_MODAL_VISIBLE]: (state, action) => (
      { ...state, isDeleteModalVisible: action.payload }
    ),
    [actionTypes.SET_CONDOR_FINSIGHT_COMPANY_DEAL_ID_DOWNLOADING]: (state, action) => (
      { ...state, dealIdDownloading: action.payload }
    ),
  },
);
